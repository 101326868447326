<template>
  <div>
    <div class="flex max-w1200 mrauto pd40 a-center">
      <div class="flex1">
        <div class="per-w80 mrauto">
          <img src="../../assets/img/login_1.png" alt="" class="per-w100" />
        </div>
        <div class="per-w100 h405 mrauto mt20">
          <img src="../../assets/img/login_im.jpg" alt="" class="per-w100 h405" />
        </div>
      </div>
      <div class="per-w50 bor-E6E6E6 login">
        <div class="ptb15lr20">
          <div class="fs-22 col-333333">账号登录</div>
          <div class="mt20 mb15">
            <el-input v-model="form.Phone" placeholder="请输入手机号"></el-input>
          </div>
          <div class="mt20 mb15 flex">
            <el-input v-model="form.Code" placeholder="请输入验证码">
              <el-button
                slot="suffix"
                size="mini"
                style="border: none"
                @click="sendcode"
                :disabled="!show"
              >
                获取验证码
                <span v-show="!show" class="count">({{ count }}s)</span>
              </el-button>
            </el-input>
          </div>
          <div class="pd20">
            <div
              class="per-w100 h50 br100 bg-CC0000 col-ffffff fs-20 cup flex a-center j-center"
              @click="submitlogin"
            >
              立 即 登 录
            </div>
          </div>
          <div class="pd20 flex a-center">
            微信扫码快捷登录：
            <img
              src="../../assets/img/weixin.png"
              alt=""
              class="wh30 cup"
              @click="wexinlogin"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="payflag"
      width="30%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="wh400 mrauto">
        <img :src="paycode" alt="" class="wh400" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { login, send_code, create_code, select_login } from "../../utils/api.js";
export default {
  data() {
    return {
      paycode: "",
      payflag: false,
      timer: null, //计时器
      code: "", //验证码
      count: "", //倒计时
      show: true, //控制按钮
      form: {
        Phone: "",
        Code: "",
      },
      scene: "",
      timer2: "",
    };
  },
  methods: {
    wexinlogin() {
      create_code({})
        .then((res) => {
          console.log(res);
          if (res.code != 200) {
            return;
          }
          this.payflag = true;
          this.paycode = res.data.img;
          this.scene = res.data.key;
          this.timer2 = window.setInterval(() => {
            setTimeout(() => {
              this.weixinstatue();
            }, 0);
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    weixinstatue() {
      select_login({
        scene: this.scene,
      })
        .then((res) => {
          console.log(res);
          if (res.code != 200) {
            return;
          }
          this.$message.success(res.msg);
          this.payflag = false;
          window.clearInterval(this.timer2);
          window.localStorage.setItem("userInfo", JSON.stringify(res.data));
           window.localStorage.setItem("uid", res.data.id);
          window.localStorage.setItem("user_type", res.data.user_type);
          setTimeout(() => {
            this.$router.push({
              path: "/indexer",
              query: { navid: 1 },
            });
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitlogin() {
      let that = this;
      login({
        phone: that.form.Phone,
        code: that.form.Code,
      })
        .then((res) => {
          console.log(res);
          if (res.code != 200) {
            if(res.msg=='用户不存在'){
              this.$message.error('用户不存在，快用微信扫码，进入佰铺邦小程序创建用户吧~');
            }else{
              this.$message.error(res.msg);
            }
            return;
          }
          this.$message.success(res.msg);
          window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
          window.localStorage.setItem("uid", res.data.user.id);
          window.localStorage.setItem("user_type", res.data.user.user_type);
          setTimeout(() => {
            this.$router.push({
              path: "/indexer",
              query: { navid: 1 },
            });
            // let routeData = this.$router.resolve({
            //   path: "/indexer",
            //   query: { navid: 1 },
            // });
            // window.open(routeData.href, "_blank");
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
        });
    },
    //获得六位数验证码
    generatedCode() {
      const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let code = "";
      for (let i = 0; i < 6; i++) {
        let index = Math.floor(Math.random() * 10);
        code += random[index];
      }
      console.log(code);
      this.code = code;
      return code;
    },
    //发送验证码
    sendcode() {
      let that = this;
      send_code({
        phone: that.form.Phone,
      })
        .then((res) => {
          if (res.code != 200) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success(res.msg);
          if (!that.timer) {
            that.count = 60;
            that.show = false;
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= 60) {
                that.count--;
              } else {
                that.show = true;
                clearInterval(that.timer); // 清除定时器
                that.timer = null;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
        });
    },
    //在输入完成后可以判断一下
    blurcheck() {
      if (this.form.Code.length < 6) {
        return false;
      } else {
        if (this.code != this.form.Code && this.form.Code != "") {
          this.$message.error("验证码错误");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.login {
  .el-input {
    width: 100%;
    font-size: 0.875rem;
  }
  .el-input__inner {
    width: 100%;
    border-radius: 6.25rem;
    height: 3.125rem;
    line-height: 3.125rem;
    padding: 0 0.9375rem;
  }
  .el-input__suffix{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-button--mini{
    font-size: 0.75rem;
    padding: 0rem 0.9375rem;
    line-height: 1.875rem;
  }
}
</style>
